import React from 'react';
import { LineContext } from 'components/context';
import AppointmentsProgressWithAuthPage from 'pages/appointments/progresswithauth';
import usePages from 'src/customHooks/pages';

export default function Home(props) {
  const { message, token } = usePages();
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <AppointmentsProgressWithAuthPage
          {...props}
          {...message}
        ></AppointmentsProgressWithAuthPage>
      </LineContext.Provider>
    );
  }
  return null;
}
